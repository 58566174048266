
import { commonProps } from '../../utils/prismic';
import { normalizeFormData } from '../../utils/helpers/normalize-form-data.js';

export default {
  components: {
    FormBlock: () => import('../form-block'),
    ConfirmationBlock: () => import('../confirmation-block'),
    Comparadise: () => import('../comparadise'),
  },
  props: commonProps,
  data() {
    return {
      confirmationScreen: false,
      formData: null,
      withSteps: this.slice?.data?.form?.withSteps,
    };
  },
  methods: {
    handleFormSuccess(res) {
      const formType = this.slice.data.form.type.toLowerCase();
      this.formData = normalizeFormData(res.formData, formType);
      this.confirmationScreen = true;
      this.$emit('formSuccess');
    },
  },
};
